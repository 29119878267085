import s from "./Home.module.scss"

export const Home = () => {
  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.glint} />

        <strong>Out</strong>

        <div className={s.words}>
          <span className={s.source}>source?</span>
          <span className={s.ceris}>ceris.</span>
        </div>
      </div>

      <div className={s.right}>
        <div className={s.glint} />

        <div className={s.header} />

        <div className={s.description}>
          <span className={s.headline}>Industry leading web development</span>
          <span className={s.skills}>in React, Typescript, and Sass.</span>
        </div>

        <div className={s.contact}>
          <span>e: <a href="mailto:out@cer.is">out@cer.is</a></span>
          <span>|</span>
          <span>m: <a href="tel:07*********">07*********</a></span>
        </div>
      </div>
    </div>
  )
}
